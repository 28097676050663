import React from "react";

import { handleSectionNavigation } from "util";
import { Column } from "components/Column";
import { Row } from "components/Row";
import { Image } from "components/Image";
import { Text } from "components/Text";
import { Line } from "components/Line";
import { Button } from "components/Button";
import { List } from "components/List";
import { Stack } from "components/Stack";

const LandingPageSitePage = () => {
  return (
    <Column className="bg-white_A700 border border-black_900 border-solid items-center justify-start ml-[auto] mr-[auto] w-[100%]" id="top-page">
      <Column className="bg-gray_50 justify-start self-stretch w-[100%]">
        <div className="3xl:mt-[48px] 3xl:mx-[135px] lg:mt-[31px] lg:mx-[87px] mt-[40px] mx-[113px] w-[62%] xl:mt-[35px] xl:mx-[100px]">
          <Row className="font-montserratalternates items-center justify-between px-[0] self-stretch w-[100%]">
            <Row className="font-montserratalternates items-center justify-start w-[32%]">
              <Image
                src="img_logo.svg"
                className="2xl:h-[65px] 2xl:w-[64px] 3xl:h-[78px] 3xl:w-[77px] h-[64.71px] lg:h-[51px] lg:w-[50px] object-contain w-[64.71px] xl:h-[58px] xl:w-[57px]"
                alt="logo"
              />
              <Text className="2xl:mb-[11px] 2xl:ml-[13px] 3xl:mb-[14px] 3xl:ml-[15px] 3xl:mt-[13px] 3xl:text-fs48 font-bold lg:mb-[9px] lg:ml-[10px] lg:mt-[8px] lg:text-fs31 mb-[11.709999px] ml-[13.290009px] mt-[11px] text-fs40 text-left text-pink_A200 tracking-ls1 xl:mb-[10px] xl:ml-[11px] xl:mt-[9px] xl:text-fs35">{`dbgtech`}</Text>
            </Row>
            <Row className="2xl:mb-[18px] 3xl:my-[22px] font-inter items-center justify-center lg:my-[14px] mb-[18.71px] mt-[19px] w-[35%] xl:my-[16px]">
              <Text className="3xl:text-fs21 font-bold lg:text-fs14 text-bluegray_900 text-fs18 text-left xl:text-fs16">{`Home`}</Text>
              <Text
                className="common-pointer 3xl:ml-[57px] 3xl:text-fs21 font-medium lg:ml-[37px] lg:text-fs14 ml-[48px] text-bluegray_900 text-fs18 text-left xl:ml-[42px] xl:text-fs16"
                onClick={() => handleSectionNavigation("block2")}
              >{`How it works`}</Text>
              <Text className="3xl:ml-[57px] 3xl:text-fs21 font-medium lg:ml-[37px] lg:text-fs14 ml-[48px] text-bluegray_900 text-fs18 text-left xl:ml-[42px] xl:text-fs16">{`News`}</Text>
            </Row>
            <Line className="2xl:h-[33px] 2xl:mb-[16px] 3xl:h-[39px] 3xl:mb-[20px] 3xl:mt-[19px] h-[32px] lg:h-[25px] lg:my-[12px] mb-[16.71px] mt-[16px] w-[1px] xl:h-[29px] xl:my-[14px]" />
          </Row>
        </div>
        <Column
          className="2xl:mt-[144px] 3xl:mb-[142px] 3xl:mt-[173px] items-center lg:mb-[92px] lg:mt-[112px] mb-[119px] mt-[144.29001px] self-stretch w-[100%] xl:mb-[105px] xl:mt-[128px]"
          id="block1"
        >
          <Row className="font-dmsans items-center justify-start self-stretch w-[100%]">
            <Column className="3xl:ml-[139px] justify-start lg:ml-[90px] ml-[116px] w-[34%] xl:ml-[103px]">
              <Text className="2xl:tracking-ls4 3xl:text-fs96 3xl:tracking-ls4 font-bold font-dmsans lg:text-fs62 lg:tracking-ls3 self-stretch text-bluegray_900 text-fs80 text-left tracking-ls32 w-[100%] xl:text-fs71 xl:tracking-ls3">
                {
                  <>
                    {`Sua ideia `}
                    <br />
                    {`seu empresa`}
                    <br />
                    {`de sucesso`}
                  </>
                }
              </Text>
              <Text className="3xl:mr-[12px] 3xl:mt-[86px] 3xl:text-fs24 font-inter font-normal lg:mr-[7px] lg:mt-[56px] lg:text-fs15 mr-[10px] mt-[72px] text-bluegray_900 text-fs20 text-left w-[98%] xl:mr-[8px] xl:mt-[64px] xl:text-fs17">
                {
                  <>
                    {`Sua ideia está no papel ou planejada iremos ajudar`}
                    <br />
                    {`você a sair do zero ao case de sucesso com`}
                    <br />
                    {`maximo engajamento. Só entre nesse foguete.`}
                  </>
                }
              </Text>
              <Button className="3xl:mr-[12px] 3xl:mt-[48px] 3xl:px-[42px] 3xl:py-[34px] 3xl:text-fs24 bg-pink_A200 border-bw font-bold font-inter lg:mr-[7px] lg:mt-[31px] lg:px-[27px] lg:py-[22px] lg:text-fs15 mr-[10px] mt-[40px] px-[35px] py-[29px] rounded-radius40 text-center text-fs20 text-white_A700 w-[38%] xl:mr-[8px] xl:mt-[35px] xl:px-[31px] xl:py-[25px] xl:text-fs17">{`Em breve`}</Button>
            </Column>
            <Image
              src="img_illustration.svg"
              className="2xl:h-[520px] 2xl:ml-[63px] 2xl:mr-[103px] 3xl:h-[624px] 3xl:ml-[76px] 3xl:mr-[123px] h-[519px] lg:h-[404px] lg:ml-[49px] lg:mr-[80px] mb-[1px] ml-[63.640015px] mr-[103.130005px] object-contain w-[46%] xl:h-[462px] xl:ml-[56px] xl:mr-[91px]"
              alt="illustration"
            />
          </Row>
        </Column>
      </Column>
      <Column
        className="bg-gray_50 justify-center self-stretch w-[100%]"
        id="block2"
      >
        <Column className="3xl:mt-[144px] font-montserratalternates items-end lg:mt-[93px] mt-[120px] self-stretch w-[100%] xl:mt-[106px]">
          <Text className="3xl:mx-[289px] 3xl:text-fs48 font-semibold lg:mx-[187px] lg:text-fs31 mx-[241px] text-bluegray_900 text-center text-fs40 tracking-ls1 w-[61%] xl:mx-[214px] xl:text-fs35">
            <span className="text-deep_purple_700 text-fs40 tracking-ls1 font-dmsans text-center font-bold underline lg:text-fs31 xl:text-fs35 3xl:text-fs48">
              <>{`Dbgtech`}</>
            </span>
            <span className="text-bluegray_900 text-fs40 tracking-ls1 font-dmsans text-center font-bold lg:text-fs31 xl:text-fs35 3xl:text-fs48">
              <>{` é voltada para uma rapida evolução e performace do seu produto com foco total na sua ideia. Vamos te ajudar em todos os passos que tiver duvida.`}</>
            </span>
          </Text>
        </Column>
        <Column className="3xl:mb-[192px] 3xl:mt-[62px] items-center lg:mb-[124px] lg:mt-[40px] mb-[160px] mt-[52px] self-stretch w-[100%] xl:mb-[142px] xl:mt-[46px]">
          <Row className="items-center justify-start self-stretch w-[100%]">
            <List
              className="3xl:gap-[49px] 3xl:ml-[139px] flex-wrap gap-[41px] grid grid-cols-2 lg:gap-[31px] lg:ml-[90px] min-h-[auto] ml-[116px] w-[55%] xl:gap-[36px] xl:ml-[103px]"
              orientation="horizontal"
            >
              <Stack className="2xl:h-[559px] 3xl:h-[670px] bg-deep_purple_A200 font-dmsans h-[558px] lg:h-[434px] rounded-radius24 w-[100%] xl:h-[497px]">
                <Column className="3xl:top-[61px] absolute inset-x-[0] items-center justify-start lg:top-[39px] mx-[auto] top-[51px] w-[72%] xl:top-[45px]">
                  <Text className="3xl:text-fs38 font-normal lg:text-fs24 self-stretch text-center text-fs32 text-white_A700 tracking-ls1 w-[100%] xl:text-fs28">{`Vendas com alta performace`}</Text>
                  <Text className="2xl:tracking-ls2 3xl:mt-[57px] 3xl:text-fs134 3xl:tracking-ls2 font-medium lg:mt-[37px] lg:text-fs87 lg:tracking-ls1 mt-[48px] mx-[auto] text-center text-fs112 text-white_A700 tracking-ls112 xl:mt-[42px] xl:text-fs99 xl:tracking-ls1">{`6%`}</Text>
                </Column>
                <Image
                  src="img_graphic.svg"
                  className="2xl:h-[559px] 3xl:h-[670px] absolute h-[558px] inset-[0] lg:h-[434px] object-cover self-stretch w-[100%] xl:h-[497px]"
                  alt="graphic"
                />
              </Stack>
              <Stack className="2xl:h-[559px] 3xl:h-[670px] bg-pink_A200 font-dmsans h-[558px] lg:h-[434px] rounded-radius24 w-[100%] xl:h-[497px]">
                <Column className="3xl:top-[61px] absolute inset-x-[0] items-center justify-start lg:top-[39px] mx-[auto] top-[51px] w-[72%] xl:top-[45px]">
                  <Text className="3xl:text-fs38 font-normal lg:text-fs24 self-stretch text-center text-fs32 text-white_A700 tracking-ls1 w-[100%] xl:text-fs28">{`Máxima renteção de clientes`}</Text>
                  <Text className="2xl:tracking-ls2 3xl:mt-[57px] 3xl:text-fs134 3xl:tracking-ls2 font-medium lg:mt-[37px] lg:text-fs87 lg:tracking-ls1 mt-[48px] mx-[auto] text-center text-fs112 text-white_A700 tracking-ls112 xl:mt-[42px] xl:text-fs99 xl:tracking-ls1">{`12%`}</Text>
                </Column>
                <Image
                  src="img_graphic_1.svg"
                  className="2xl:h-[559px] 3xl:h-[670px] absolute h-[558px] inset-[0] lg:h-[434px] object-cover self-stretch w-[100%] xl:h-[497px]"
                  alt="graphic"
                />
              </Stack>
            </List>
            <Column className="3xl:ml-[48px] 3xl:mr-[139px] bg-deep_purple_A200 font-dmsans items-center justify-end lg:ml-[31px] lg:mr-[90px] ml-[40px] mr-[116px] rounded-radius24 w-[26%] xl:ml-[35px] xl:mr-[103px]">
              <Text className="3xl:mt-[61px] 3xl:text-fs38 font-normal lg:mt-[39px] lg:text-fs24 mt-[51px] mx-[auto] text-center text-fs32 text-white_A700 tracking-ls1 xl:mt-[45px] xl:text-fs28">{`Explore sua ideia`}</Text>
              <Text className="2xl:tracking-ls2 3xl:mt-[103px] 3xl:text-fs134 3xl:tracking-ls2 font-medium lg:mt-[66px] lg:text-fs87 lg:tracking-ls1 mt-[86px] mx-[auto] text-center text-fs112 text-white_A700 tracking-ls112 xl:mt-[76px] xl:text-fs99 xl:tracking-ls1">{`99%`}</Text>
              <Image
                src="img_graphic_2.svg"
                className="2xl:h-[231px] 3xl:h-[277px] 3xl:mt-[49px] h-[230px] lg:h-[179px] lg:mt-[31px] mt-[41px] mx-[auto] object-contain w-[79%] xl:h-[205px] xl:mt-[36px]"
                alt="graphic"
              />
            </Column>
          </Row>
        </Column>
      </Column>
      <Stack className="2xl:h-[759px] 3xl:h-[911px] bg-gray_100 h-[758px] lg:h-[590px] mx-[auto] rounded-radius88 w-[84%] xl:h-[675px]">
        <Image
          src="img_illustration_1.svg"
          className="2xl:h-[516px] 2xl:right-[108px] 3xl:h-[619px] 3xl:right-[130px] absolute h-[515.22px] inset-y-[0] lg:h-[401px] lg:right-[84px] my-[auto] object-contain right-[108.39px] w-[42%] xl:h-[459px] xl:right-[96px]"
          alt="illustration"
        />
        <Column className="3xl:left-[124px] absolute font-dmsans h-[max-content] inset-y-[0] justify-start left-[104px] lg:left-[80px] my-[auto] w-[48%] xl:left-[92px]">
          <Text className="3xl:text-fs86 font-bold font-dmsans lg:text-fs56 self-stretch text-bluegray_900 text-fs72 text-left tracking-ls1 xl:text-fs64">{`Você não encontrou`}</Text>
          <Text className="3xl:mr-[12px] 3xl:mt-[57px] 3xl:text-fs24 font-inter font-medium lg:mr-[7px] lg:mt-[37px] lg:text-fs15 mr-[10px] mt-[48px] text-bluegray_600 text-fs20 text-left w-[83%] xl:mr-[8px] xl:mt-[42px] xl:text-fs17">
            {
              <>
                {`Estamos reformando a pagina com mais `}
                <br />
                {`informações para propocionar o melhor.`}
              </>
            }
          </Text>
          <Button className="3xl:mr-[12px] 3xl:mt-[84px] 3xl:px-[42px] 3xl:py-[34px] 3xl:text-fs24 bg-white_A700 border-bw font-bold font-inter lg:mr-[7px] lg:mt-[54px] lg:px-[27px] lg:py-[22px] lg:text-fs15 mr-[10px] mt-[70px] px-[35px] py-[29px] rounded-radius40 shadow-bs text-center text-deep_purple_A200 text-fs20 w-[32%] xl:mr-[8px] xl:mt-[62px] xl:px-[31px] xl:py-[25px] xl:text-fs17">{`Em breve`}</Button>
        </Column>
      </Stack>
      <Row className="items-center justify-center mx-[auto] rounded-radius88 w-[84%]">
        <Image
          src="img_illustration_2.svg"
          className="2xl:h-[462px] 2xl:mb-[119px] 3xl:h-[554px] 3xl:mb-[143px] 3xl:ml-[124px] 3xl:mt-[144px] h-[461.2px] lg:h-[359px] lg:ml-[80px] lg:my-[93px] mb-[119.80005px] ml-[104px] mt-[120px] object-contain w-[36%] xl:h-[411px] xl:ml-[92px] xl:my-[106px]"
          alt="illustration"
        />
        <Column className="2xl:ml-[87px] 3xl:mb-[162px] 3xl:ml-[104px] 3xl:mr-[112px] 3xl:mt-[163px] font-dmsans justify-start lg:ml-[67px] lg:mr-[73px] lg:my-[105px] mb-[135px] ml-[87.420044px] mr-[94px] mt-[136px] w-[40%] xl:ml-[77px] xl:mr-[83px] xl:my-[120px]">
          <Text className="3xl:text-fs86 font-bold font-dmsans lg:text-fs56 self-stretch text-bluegray_900 text-fs72 text-left tracking-ls1 xl:text-fs64">{`Foco total na sua ideia`}</Text>
          <Text className="3xl:mr-[10px] 3xl:mt-[57px] 3xl:text-fs24 font-inter font-medium lg:mr-[7px] lg:mt-[37px] lg:text-fs15 mr-[9px] mt-[48px] text-bluegray_600 text-fs20 text-left w-[98%] xl:mr-[8px] xl:mt-[42px] xl:text-fs17">{`Criamos um planejamento passo a passo para evoluir seu produto do zero ao 100%`}</Text>
          <Button className="3xl:mr-[12px] 3xl:mt-[120px] 3xl:px-[42px] 3xl:py-[34px] 3xl:text-fs24 bg-white_A700 border-bw font-bold font-inter lg:mr-[7px] lg:mt-[77px] lg:px-[27px] lg:py-[22px] lg:text-fs15 mr-[10px] mt-[100px] px-[35px] py-[29px] rounded-radius40 shadow-bs text-center text-deep_purple_A200 text-fs20 w-[38%] xl:mr-[8px] xl:mt-[88px] xl:px-[31px] xl:py-[25px] xl:text-fs17">{`Em breve`}</Button>
        </Column>
      </Row>
      <Row className="bg-gray_100 font-dmsans items-center justify-center mx-[auto] rounded-radius88 w-[84%]">
        <Column className="3xl:mb-[165px] 3xl:ml-[133px] 3xl:mt-[166px] justify-start lg:mb-[107px] lg:ml-[86px] lg:mt-[108px] mb-[138px] ml-[111px] mt-[139px] w-[40%] xl:mb-[122px] xl:ml-[98px] xl:mt-[123px]">
          <Text className="3xl:text-fs72 font-bold font-dmsans lg:text-fs46 self-stretch text-bluegray_900 text-fs60 text-left tracking-ls1 xl:text-fs53">{`Sua ideia uma evolução`}</Text>
          <Text className="3xl:mr-[12px] 3xl:mt-[86px] 3xl:text-fs24 font-inter font-medium lg:mr-[7px] lg:mt-[56px] lg:text-fs15 mr-[10px] mt-[72px] text-bluegray_600 text-fs20 text-left w-[97%] xl:mr-[8px] xl:mt-[64px] xl:text-fs17">{`Criamos varias pipelines para desenvolver e entrega de forma eficiente seu produto.`}</Text>
          <Button className="3xl:mr-[12px] 3xl:mt-[84px] 3xl:px-[42px] 3xl:py-[34px] 3xl:text-fs24 bg-white_A700 border-bw font-bold font-inter lg:mr-[7px] lg:mt-[54px] lg:px-[27px] lg:py-[22px] lg:text-fs15 mr-[10px] mt-[70px] px-[35px] py-[29px] rounded-radius40 shadow-bs text-center text-deep_purple_A200 text-fs20 w-[38%] xl:mr-[8px] xl:mt-[62px] xl:px-[31px] xl:py-[25px] xl:text-fs17">{`Em breve`}</Button>
        </Column>
        <Image
          src="img_illustration_3.svg"
          className="2xl:h-[438px] 3xl:h-[525px] 3xl:ml-[40px] 3xl:mr-[121px] 3xl:my-[144px] h-[437px] lg:h-[340px] lg:ml-[26px] lg:mr-[78px] lg:my-[93px] ml-[34px] mr-[101px] my-[120px] object-contain w-[39%] xl:h-[389px] xl:ml-[30px] xl:mr-[89px] xl:my-[106px]"
          alt="illustration"
        />
      </Row>
      <Stack className="2xl:h-[841px] 3xl:h-[1009px] 3xl:mt-[148px] bg-deep_purple_A200 h-[840px] lg:h-[654px] lg:mt-[96px] mt-[124px] mx-[auto] rounded-radius88 w-[84%] xl:h-[748px] xl:mt-[110px]">
        <Image
          src="img_graphic_3.svg"
          className="2xl:h-[841px] 3xl:h-[1009px] absolute h-[840px] inset-[0] lg:h-[654px] object-cover self-stretch w-[100%] xl:h-[748px]"
          alt="graphic"
        />
        <Column className="3xl:left-[124px] absolute font-dmsans h-[max-content] inset-y-[0] justify-start left-[104px] lg:left-[80px] my-[auto] w-[49%] xl:left-[92px]">
          <Text className="2xl:tracking-ls1 3xl:text-fs105 3xl:tracking-ls2 font-bold font-dmsans lg:text-fs68 lg:tracking-ls1 self-stretch text-fs88 text-left text-white_A700 tracking-ls088 xl:text-fs78 xl:tracking-ls1">{`Realidade vs Martketing`}</Text>
          <Text className="3xl:mr-[7px] 3xl:mt-[153px] 3xl:text-fs24 font-inter font-medium lg:mr-[4px] lg:mt-[99px] lg:text-fs15 mr-[6px] mt-[128px] text-fs20 text-gray_200 text-left w-[99%] xl:mr-[5px] xl:mt-[113px] xl:text-fs17">{`Cusotmizamos seu produto para extrair o maximo de informações para evolução.`}</Text>
          <Button className="3xl:mr-[12px] 3xl:mt-[93px] 3xl:px-[42px] 3xl:py-[34px] 3xl:text-fs24 bg-bluegray_900 border-bw font-bold font-inter lg:mr-[7px] lg:mt-[60px] lg:px-[27px] lg:py-[22px] lg:text-fs15 mr-[10px] mt-[78px] px-[35px] py-[29px] rounded-radius40 text-center text-fs20 text-white_A700 w-[32%] xl:mr-[8px] xl:mt-[69px] xl:px-[31px] xl:py-[25px] xl:text-fs17">{`Em breve`}</Button>
        </Column>
      </Stack>
      <div className="self-stretch w-[100%]">
        <Row className="justify-evenly px-[0] self-stretch w-[100%]">
          <Column className="3xl:mb-[144px] 3xl:mt-[247px] font-inter justify-start lg:mb-[93px] lg:mt-[160px] mb-[120px] mt-[206px] w-[20%] xl:mb-[106px] xl:mt-[183px]">
            <Column className="items-center self-stretch w-[100%]">
              <Row className="font-montserratalternates items-center justify-start self-stretch w-[100%]">
                <Image
                  src="img_graphic_4.svg"
                  className="2xl:h-[65px] 2xl:w-[64px] 3xl:h-[78px] 3xl:w-[77px] h-[64.71px] lg:h-[51px] lg:w-[50px] object-contain w-[64.71px] xl:h-[58px] xl:w-[57px]"
                  alt="graphic"
                />
                <Text className="2xl:mb-[11px] 2xl:ml-[13px] 3xl:mb-[14px] 3xl:ml-[15px] 3xl:mr-[8px] 3xl:mt-[13px] 3xl:text-fs48 font-bold lg:mb-[9px] lg:ml-[10px] lg:mr-[5px] lg:mt-[8px] lg:text-fs31 mb-[11.709961px] ml-[13.290009px] mr-[7px] mt-[11px] text-bluegray_900 text-fs40 text-left tracking-ls1 xl:mb-[10px] xl:ml-[11px] xl:mr-[6px] xl:mt-[9px] xl:text-fs35">{`dbgtech`}</Text>
              </Row>
            </Column>
            <Image
              src="img_socialmedia.svg"
              className="2xl:h-[33px] 3xl:h-[39px] 3xl:mr-[12px] 3xl:mt-[115px] h-[32px] lg:h-[25px] lg:mr-[7px] lg:mt-[74px] mr-[10px] mt-[96px] object-contain w-[87%] xl:h-[29px] xl:mr-[8px] xl:mt-[85px]"
              alt="socialmedia"
            />
            <Text className="3xl:mr-[12px] 3xl:mt-[28px] 3xl:text-fs24 font-medium lg:mr-[7px] lg:mt-[18px] lg:text-fs15 ml-[1px] mr-[10px] mt-[24px] text-bluegray_900 text-fs20 text-left xl:mr-[8px] xl:mt-[21px] xl:text-fs17">{`+11 999 121 91 00`}</Text>
            <Text className="2xl:mt-[391px] 3xl:mt-[469px] 3xl:text-fs19 font-normal lg:mt-[304px] lg:text-fs12 mt-[391.29004px] self-stretch text-bluegray_900 text-fs16 text-left xl:mt-[348px] xl:text-fs14">{`© 2022. DGTECH. All Rights Reserved.`}</Text>
          </Column>
          <Column className="3xl:mb-[365px] 3xl:mt-[247px] font-inter justify-start lg:mb-[236px] lg:mt-[160px] mb-[304px] mt-[206px] w-[48%] xl:mb-[270px] xl:mt-[183px]">
            <Column className="items-center self-stretch w-[100%]">
              <Row className="font-inter justify-start self-stretch w-[100%]">
                <Column className="justify-start w-[18%]">
                  <Text className="3xl:mr-[12px] 3xl:text-fs24 font-medium lg:mr-[7px] lg:text-fs15 mr-[10px] text-bluegray_900 text-fs20 text-left xl:mr-[8px] xl:text-fs17" onClick={() => handleSectionNavigation("top-page")}>{`Home`} </Text>
                  <Text className="3xl:mt-[19px] 3xl:text-fs24 font-medium lg:mt-[12px] lg:text-fs15 mt-[16px] self-stretch text-bluegray_900 text-fs20 text-left xl:mt-[14px] xl:text-fs17">{`How it works`}</Text>
                  <Text className="3xl:mr-[12px] 3xl:mt-[19px] 3xl:text-fs24 font-medium lg:mr-[7px] lg:mt-[12px] lg:text-fs15 mr-[10px] mt-[16px] text-bluegray_900 text-fs20 text-left xl:mr-[8px] xl:mt-[14px] xl:text-fs17">{`News`}</Text>
                  <Text className="3xl:mr-[12px] 3xl:mt-[19px] 3xl:text-fs24 font-medium lg:mr-[7px] lg:mt-[12px] lg:text-fs15 mr-[10px] mt-[16px] text-bluegray_900 text-fs20 text-left xl:mr-[8px] xl:mt-[14px] xl:text-fs17">{`About`}</Text>
                  <Text className="3xl:mr-[12px] 3xl:mt-[19px] 3xl:text-fs24 font-medium lg:mr-[7px] lg:mt-[12px] lg:text-fs15 mr-[10px] mt-[16px] text-bluegray_900 text-fs20 text-left xl:mr-[8px] xl:mt-[14px] xl:text-fs17">{`Support`}</Text>
                </Column>
                <Column className="3xl:mb-[55px] 3xl:ml-[124px] justify-start lg:mb-[35px] lg:ml-[80px] mb-[46px] ml-[104px] w-[27%] xl:mb-[40px] xl:ml-[92px]">
                  <Text className="3xl:text-fs24 font-medium lg:text-fs15 self-stretch text-bluegray_900 text-fs20 text-left xl:text-fs17">{`Terms & Conditions`}</Text>
                  <Text className="3xl:mr-[12px] 3xl:mt-[19px] 3xl:text-fs24 font-medium lg:mr-[7px] lg:mt-[12px] lg:text-fs15 mr-[10px] mt-[16px] text-bluegray_900 text-fs20 text-left xl:mr-[8px] xl:mt-[14px] xl:text-fs17">{`Privacy Policy`}</Text>
                  <Text className="3xl:mr-[12px] 3xl:mt-[19px] 3xl:text-fs24 font-medium lg:mr-[7px] lg:mt-[12px] lg:text-fs15 mr-[10px] mt-[16px] text-bluegray_900 text-fs20 text-left xl:mr-[8px] xl:mt-[14px] xl:text-fs17">{`Careers`}</Text>
                  <Text className="3xl:mr-[12px] 3xl:mt-[19px] 3xl:text-fs24 font-medium lg:mr-[7px] lg:mt-[12px] lg:text-fs15 mr-[10px] mt-[16px] text-bluegray_900 text-fs20 text-left xl:mr-[8px] xl:mt-[14px] xl:text-fs17">{`Help Center`}</Text>
                </Column>
                <Image
                  src="img_toparrow.svg"
                  className="common-pointer 2xl:h-[57px] 3xl:h-[68px] 3xl:mb-[189px] 3xl:ml-[258px] 3xl:w-[67px] h-[56px] lg:h-[44px] lg:mb-[122px] lg:ml-[167px] lg:w-[43px] mb-[158px] ml-[215px] object-contain w-[56px] xl:h-[50px] xl:mb-[140px] xl:ml-[191px] xl:w-[49px]"
                  onClick={() => handleSectionNavigation("top-page")}
                  alt="toparrow"
                />
              </Row>
            </Column>
            <Text className="3xl:mr-[12px] 3xl:mt-[199px] 3xl:text-fs28 font-medium lg:mr-[7px] lg:mt-[129px] lg:text-fs18 mr-[10px] mt-[166px] text-black_900 text-fs24 text-left xl:mr-[8px] xl:mt-[147px] xl:text-fs21">{`Sign Up to Our Newsletter`}</Text>
            <Column className="3xl:mt-[28px] items-center lg:mt-[18px] mt-[24px] self-stretch w-[100%] xl:mt-[21px]">
              <Column className="justify-start self-stretch w-[100%]">
                <Column className="items-center self-stretch w-[100%]">
                  <Row className="font-inter justify-start self-stretch w-[100%]">
                    <Text className="3xl:text-fs21 font-normal lg:mt-[2px] lg:text-fs14 mt-[3px] text-bluegray_900 text-fs18 text-left xl:mt-[2px] xl:text-fs16">{`example@mail.com`}</Text>
                    <Text className="3xl:ml-[230px] 3xl:mr-[277px] 3xl:text-fs24 font-normal lg:ml-[149px] lg:mr-[179px] lg:text-fs15 ml-[192px] mr-[231px] text-bluegray_900 text-fs20 text-left xl:ml-[170px] xl:mr-[205px] xl:text-fs17">{`Subscribe`}</Text>
                  </Row>
                </Column>
                <Line className="3xl:mr-[12px] 3xl:mt-[9px] bg-bluegray_900 h-[2px] lg:mr-[7px] lg:mt-[6px] mr-[10px] mt-[8px] w-[66%] xl:mr-[8px] xl:mt-[7px]" />
              </Column>
            </Column>
          </Column>
        </Row>
      </div>
    </Column>
  );
};

export default LandingPageSitePage;
