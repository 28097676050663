import Routes from "./Routes";

function App() {
  // Import the functions you need from the SDKs you need
  const initializeApp = require("firebase/app");
  const getAnalytics = require("firebase/analytics");
  // TODO: Add SDKs for Firebase products that you want to use
  // https://firebase.google.com/docs/web/setup#available-libraries

  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const firebaseConfig = {
    apiKey: "AIzaSyAPSkEc8Lyyv-2gGOgxGT9Gl5cGWJkyIfA",
    authDomain: "dgbtech-app.firebaseapp.com",
    projectId: "dgbtech-app",
    storageBucket: "dgbtech-app.appspot.com",
    messagingSenderId: "796327203823",
    appId: "1:796327203823:web:45456d5691475a2ce29c1a",
    measurementId: "G-6GE6WC8D87"
  };

  // Initialize Firebase
  // const app = initializeApp(firebaseConfig);
  // const analytics = getAnalytics(app);
  return <Routes />;
}

export default App;
